export const onIdle = (fn) => {
    if ("scheduler" in window) {
      return window.scheduler.postTask(fn, {
        priority: "background",
      });
    }
    if ("requestIdleCallback" in window) {
      return requestIdleCallback(fn);
    }
    setTimeout(fn, 0);
  };
  